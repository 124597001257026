//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "CesiumViewer",
  data() {
    return {
      animation: true,
      timeline: true,
      baseLayerPicker: false,
      fullscreenButton: true,
      infoBox: true,
      fullscreenElement: document.body,
      tk: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiJkYjkxYzg3MS1hMmE1LTQwNDgtYmE0Ni1hOGQ5YmRiMjUxNDAiLCJpZCI6NTc3NDAsImlhdCI6MTYzNDE1ODkwNn0.EH7JES1p11NtpGhA_dONgXW1im80ZtveYMbcjMxrMUM"
    };
  },
  mounted() {
    this.$refs.vcViewer.createPromise.then(({ Cesium, viewer }) => {
      console.log("viewer is loaded.");
      console.log(Cesium)
      console.log(viewer)
    });
  },
  methods: {
    ready(cesiumInstance) {
      const { Cesium, viewer } = cesiumInstance;
      viewer.scene.globe.depthTestAgainstTerrain = true;
      viewer.scene.globe.enableLighting = true;
      this.fullscreenElement = this.$refs.viewerContainer;
      viewer.entities.add({
        id: "成都欢迎你",
        position: Cesium.Cartesian3.fromDegrees(104.06, 30.67, 100),
        billboard: new Cesium.BillboardGraphics({
          image: "https://zouyaoji.top/vue-cesium/favicon.png",
          scale: 0.1
        }),
        label: new Cesium.LabelGraphics({
          text: "Hello Cesium",
          font: "24px sans-serif",
          horizontalOrigin: 1,
          outlineColor: new Cesium.Color(0, 0, 0, 1),
          outlineWidth: 2,
          pixelOffset: new Cesium.Cartesian2(17, -5),
          style: Cesium.LabelStyle.FILL
        })
      });
    }
  }
};
